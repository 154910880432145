import React, { useState, /*useEffect/*, useContext*/ } from 'react'
/*import { useNavigate } from 'react-router-dom';*/
import Casco from '../common/Casco'
import Question from './Question'
import { Link  } from 'react-router-dom'
import Spain from '../../assets/images/flags/spain.svg'


const Questions = (props) => {
	
	const [nQuestion,setNquestion] = useState(1)
	const [finish, setFinish] = useState(false)
	const [question,setQuestion] = useState(null)
	const numberQuestions = 5//10

	const finishedQuiz = (data) => {
		setFinish(true)
		setQuestion(data)
	}
	

	const getOrdinal = (number) => {
		var o="th";
		var n = parseInt(number);
		if (n%10 === 1){ o="th"}
		if (n%10 === 2){ o="nd"}
		if (n%10 === 1){ o="rd"}
		return o;
	}

	if(finish){
		return 	<>
					<main className="quiz final">						  
						<div className="finish-wrapper">
							<div className="quiz-title">
								<div className="left">							
									<img src={Spain}></img>							
									<h2 className="gp-name">Spain GP</h2>
								</div>
								<div className="right">
									<h2 className="finish">Final</h2>
								</div>      
							</div>
							<div className='finish-metrics'>
									<div className=''>
										<div className='finish-header'>
											<div className="finish-casco"><Casco id={props.user.id.id} nick={props.user.id.nickname}></Casco></div>
											<div className="text-position"><span className=''>{question.data.posicion}{getOrdinal(question.data.posicion)}</span> Position</div>
										</div>
										<div className='finish-content'>
											<span className='finish-icon-metrics'>
												<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1.91418 5.99993C1.91418 8.11432 3.62823 9.82836 5.74261 9.82836C7.85699 9.82836 9.57104 8.11432 9.57104 5.99993C9.57104 3.88555 7.85699 2.17151 5.74261 2.17151C3.62823 2.17151 1.91418 3.88555 1.91418 5.99993Z" stroke="#137E84" strokeWidth="0.957106" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M3.3501 5.99992L5.04204 7.69187L8.42592 4.30798" stroke="#137E84" strokeWidth="0.957106" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</span>
											<span className='border-right-gray'>{question.data.totalAciertos} hits</span>
											<span className='finish-icon-metrics ml-7'>
												<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1.48535 4.99992C1.48535 7.11517 3.2001 8.82992 5.31535 8.82992C7.4306 8.82992 9.14535 7.11517 9.14535 4.99992C9.14535 2.88467 7.4306 1.16992 5.31535 1.16992C3.2001 1.16992 1.48535 2.88467 1.48535 4.99992Z" stroke="#CC0000" strokeLinecap="round" strokeLinejoin="round"/>
													<path fillRule="evenodd" clipRule="evenodd" d="M3.4609 6.14645C3.26564 6.34171 3.26564 6.65829 3.4609 6.85355C3.65616 7.04882 3.97274 7.04882 4.16801 6.85355L5.31494 5.70662L6.46188 6.85355C6.65714 7.04882 6.97372 7.04882 7.16898 6.85355C7.36425 6.65829 7.36425 6.34171 7.16898 6.14645L6.02205 4.99951L7.16801 3.85355C7.36327 3.65829 7.36327 3.34171 7.16801 3.14645C6.97274 2.95118 6.65616 2.95118 6.4609 3.14645L5.31494 4.2924L4.16898 3.14645C3.97372 2.95118 3.65714 2.95118 3.46188 3.14645C3.26661 3.34171 3.26661 3.65829 3.46188 3.85355L4.60783 4.99951L3.4609 6.14645Z" fill="#CC0000"/>
												</svg>
											</span>
											<span className='border-right-gray'>{numberQuestions - question.data.totalAciertos} errors</span>
											<span className='finish-icon-metrics ml-7'>
												<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_120_7795)">
														<path d="M5.88763 10.3069C8.00201 10.3069 9.71605 8.59289 9.71605 6.47851C9.71605 4.36413 8.00201 2.65009 5.88763 2.65009C3.77325 2.65009 2.0592 4.36413 2.0592 6.47851C2.0592 8.59289 3.77325 10.3069 5.88763 10.3069Z" stroke="#EC0000" strokeWidth="0.957106" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
														<path d="M5.8877 6.47854V4.56433" stroke="#EC0000" strokeWidth="0.957106" strokeLinecap="round" strokeLinejoin="round"/>
														<path d="M10.1947 3.12862L9.23755 2.17151" stroke="#EC0000" strokeWidth="0.957106" strokeLinecap="round" strokeLinejoin="round"/>
														<path d="M4.93054 1.21442L6.84475 1.21442" stroke="#EC0000" strokeWidth="0.957106" strokeLinecap="round" strokeLinejoin="round"/>
													</g>
													<defs>
														<clipPath id="clip0_120_7795">
															<rect width="11.4853" height="11.4853" fill="white" transform="translate(0.14502 0.257324)"/>
														</clipPath>
													</defs>
												</svg>

											</span>
											<span className=''>{question.data.totalTiempo}&apos;&apos;</span>
										</div>
									</div>
									
							</div>
							<Link className="btn" to="/ranking">View rankings</Link>													
						</div>				
					</main>
				</>
	}
    return	<>
			<main className="questions">
				<div className="questions-title">
					<div className="left">						
						<h2 className="casco"><Casco id={props.user.id.idusuario} nick={props.user.id.nickname}></Casco>{props.user.id.nickname}</h2>
					</div>
					<div className="right">
						
					</div>      
				</div>  
				<Question number={nQuestion} changeNumber={setNquestion} numberQuestions={numberQuestions} quizFinished={finishedQuiz} 
							showModal={props.showModal}
							setShowModal={props.setShowModal}/>
			</main>
			</>

}

export default Questions
