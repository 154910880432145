import {React, useState, useEffect/*, useContext*/} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from "framer-motion"
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import Casco from '../common/Casco'
//import RankingHeader from './RankingHeader'
import MyRanking from './MyRanking'

const Rankings = (props) => {
    let navigate = useNavigate();
    const [ranking, setRanking] = useState(null);
    //const [active, setActive] = useState(1);
    //const [gpactive, setGPActive] = useState(null);
    const [gpSelectorOpen, setGpSelectorOpen] = useState(false);   
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
      }); 

    useEffect(() => {
        //console.log(props.user);
        props.setPage('ranking');  
        instance.get('/api/ranking')
        .then( (response) =>{
            console.log(response.data);            
            setRanking(response.data.ranking);
            response.data.ranking.GP.forEach( (item, index)=>{
                if (index===0){
                    //setGPActive(item.id);
                }
            })

        })
        .catch( (error) =>{   
            console.log(error);
            navigate("/");            
        })
    }, []);

    //Close Filters Dropdown on outer click
    useEffect(() => {
        const checkCloseSelector = e => {
        var closest = e.target.closest(".gps");         
        if ( gpSelectorOpen && closest === null) {
            setGpSelectorOpen(false);
        }
        }  
        document.addEventListener("mousedown", checkCloseSelector)
        return () => {        
        document.removeEventListener("mousedown", checkCloseSelector)          
        }
   }, [gpSelectorOpen])    

    const getCurrentRanking = () =>{        
        return ranking.Global.ranking        
    }

    const getCurrentMyRanking = () =>{        
        return ranking.Global.current_user        
    }



    return (<main className="rankings">        
            {ranking !== null?<>            
            <div className="ranking-container">
                <AnimatePresence>
                    { gpSelectorOpen?<motion.div animate={{ opacity:1, transition: { duration: 0.2 }}} initial={{opacity:0}} exit={{opacity:0, transition: { duration: 0.2 } }} layout className="blur"></motion.div>:null}
                </AnimatePresence>
                <div className="ranking">
                    {/*ranking.Global.ranking.map( (item, index) =>{*/
                    getCurrentRanking()!==null?
                    getCurrentRanking().map( (item, index) =>{                        
                        return (<div className={index+1 === 1?"position first":"position"} key={"position-"+index}>
                            <div className="order">
                                <span>{index+1}</span>
                            </div>
                            <div className="user-info">
                                <div className="left">
                                    
                                    <Casco id={item.idusuario} nick={item.nickname}></Casco>
                                </div>
                                <div className="center">
                                    <span className="nick">{item.nickname}</span>
                                    <div className="bottom">                                        
                                            <>
                                                <span className="hits">{item.totalAciertos} hits</span>
                                                <span className="time">{item.totalTiempo}&apos;&apos;</span>
                                            </>
                                    </div>                        
                                </div>                               
                            </div>
                        </div>)
                    }):null}                      
                    <MyRanking active={0} user={props.user} data={getCurrentMyRanking()}></MyRanking>
                </div> 
            </div>            
            </>:null}        
    </main>)
}
const mapStateToprops = (state)=>{        
    return {...state};  
}
//export default Login
export default connect(mapStateToprops,{setUser:setUser})(Rankings)