import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from 'react-redux'

function PrivateRoute( props/*{children, redirectTo}*/ ) {  
  const auth = props.user !== null;//true;//useAuth();  
  return auth ? props.children : <Navigate to={props.redirectTo} />;
}

PrivateRoute.defaultProps = {
  children: null,
  redirectTo: "/"
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  redirectTo: PropTypes.string
};

const mapStateToprops = (state)=>{    
  return {...state};

}
//export default PrivateRoute;
export default connect(mapStateToprops)(PrivateRoute)