import React, { useState, useEffect } from 'react'
import { useParams/*, useLocation*/ } from 'react-router-dom'
import { connect } from 'react-redux'
import Spain from '../../assets/images/flags/spain.svg'
import Questions from './Questions'

const Quiz = (props) => {
  let { id } = useParams();

  //const [record, setRecord] = useState(null);
  const [startQuestions,setStartquestions] = useState(false);
  const [showModal, setShowModal] = useState(false)
  
  //let navigate = useNavigate();
    
  useEffect(() => {
    props.setPage('quiz');    
  }, []); 

  if(startQuestions) return <>
                              <Questions numberQuiz={id} user={props.user} showModal={showModal} setShowModal={setShowModal}/>
                            </>
                          
  return <>
          <main className="quiz">
              
      
            <div className="preview">
              <div className="quiz-title">
                <div className="left">
                  <div className="flag_">
                    <img src={Spain} />
                  </div>
                  <h2 className="gp-name">Spain GP</h2>
                </div>
                <div className="right">
                  <h2 className="ready">Start</h2>
                </div>      
              </div>
              <div className="preview-wrapper">
                
                <div className='preview-header'>
                  <div className='preview-title'>5 questions</div>
                  <div className='preview-flagh'>
                    <svg width="41" height="33" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M40.6904 27.1048C40.6904 29.8651 38.4507 32.1048 35.6904 32.1048C33.2727 32.1048 31.2544 30.3866 30.7906 28.1054H25.5901C25.1263 30.3866 23.108 32.1048 20.6904 32.1048C18.2727 32.1048 16.2544 30.3866 15.7906 28.1054H10.5901C10.1263 30.3866 8.10802 32.1048 5.69037 32.1048C2.93008 32.1048 0.690369 29.8651 0.690369 27.1048C0.690369 24.3446 2.93008 22.1048 5.69037 22.1048C8.10802 22.1048 10.1263 23.823 10.5901 26.1043H15.7906C16.2544 23.823 18.2727 22.1048 20.6904 22.1048C23.108 22.1048 25.1263 23.823 25.5901 26.1043H30.7906C31.1888 24.1457 32.7328 22.6022 34.6916 22.2047L34.6884 14.1024L20.0916 14.1044C18.9346 14.1044 18.2855 12.829 18.9162 11.9733L22.9251 7.3187C22.9504 7.29321 22.9567 7.2572 22.9166 7.19597L19.0551 2.45206C18.2415 1.56295 18.8639 0.106445 20.0916 0.106445H34.9796C35.9253 0.106445 36.6896 0.873647 36.6896 1.82045L36.6891 22.2047C38.9713 22.6678 40.6904 24.6865 40.6904 27.1048ZM5.69037 24.1048C4.03465 24.1048 2.69037 25.4491 2.69037 27.1048C2.69037 28.7606 4.03465 30.1048 5.69037 30.1048C7.34608 30.1048 8.69037 28.7606 8.69037 27.1048C8.69037 25.4491 7.34608 24.1048 5.69037 24.1048ZM17.6904 27.1048C17.6904 25.4491 19.0347 24.1048 20.6904 24.1048C22.3461 24.1048 23.6904 25.4491 23.6904 27.1048C23.6904 28.7606 22.3461 30.1048 20.6904 30.1048C19.0347 30.1048 17.6904 28.7606 17.6904 27.1048ZM35.6904 24.1048C34.0347 24.1048 32.6904 25.4491 32.6904 27.1048C32.6904 28.7606 34.0347 30.1048 35.6904 30.1048C37.3461 30.1048 38.6904 28.7606 38.6904 27.1048C38.6904 25.4491 37.3461 24.1048 35.6904 24.1048ZM21.3514 2.10645H34.6896V12.1044H21.4394L24.5551 8.48951C25.1476 7.71339 25.0902 6.53843 24.3444 5.78902L21.3514 2.10645Z" fill="#EC0000"/>
                    </svg>
                  </div>
                </div>
                <div className='preview-content'>
                  <div className='preview-line'>&nbsp;</div>
                  <div className='preview-content-text'>
                    <p>Get as many correct answers as possible in the shortest possible time to get the best result.</p>
                  </div>
                </div>
              </div>
            </div> 
            
        

            <div className="footer-quiz">
                <div className="bt-letsgo">
                  <div className='text-bt-letsgo' onClick={() => setStartquestions(true)}>
                    Let&apos;s play!
                  </div>
                </div>
              </div>
          </main>
        </>
}

//export default Quiz
const mapStateToprops = (state)=>{  
  return {...state};
}
export default connect(mapStateToprops)(Quiz)