import {React,  useEffect } from 'react'
import { useNavigate/*, Link*/  } from 'react-router-dom'
import Trophy from '../assets/images/trophy.svg'

const Welcome = (props) => {
    
    let navigate = useNavigate();
    useEffect(() => { 
        props.setPage('welcome');
            
    },[]);

    const go = () =>{
        navigate("/login");
    }

    return <main className="welcome">
            <div className="wrapper">
                <div className="top">
                    <h2>Santander Lap Zero 
                        <span>Together to Learn</span>
                        <span>Together to Better</span>
                    </h2>
                    <img src={Trophy}></img>
                </div>
                <p>Test your knowledge of sustainability and Formula 1 in this quiz and prove that you are the person with the most dominion on the subject</p>
            </div>
            
            <button onClick={()=>{ go() }}>
                Enter
            </button>
              
    </main>
}
export default Welcome