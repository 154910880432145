import { React, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './components/Login'
import NoMatch from './components/NoMatch'
import ModalOutClient from './components/common/ModalOutClient'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
//import Dashboard from './components/dashboard/Dashboard'
import Quiz from './components/quiz/Quiz'
import Quizes from './components/quiz/Quizes'
import Welcome from './components/Welcome'
import Rankings from './components/Ranking/Rankings'
import PrivateRoute from './components/Auth/PrivateRoute'
import {AnimatePresence} from "framer-motion"

function App() {
  const [page, setPage] = useState('dashboard')
  const [isModalOutClientVisible, setIsModalOutClientVisible] = useState(false)  

  return (       
    <BrowserRouter>
      <Header 
        page={page}
        setIsModalOutClientVisible={setIsModalOutClientVisible}
      />
      <Routes>
        <Route path="/" element={<Welcome setPage={setPage} />} />
        <Route path="/login" element={<Login setPage={setPage} />} />
        <Route path="/quiz/:id" element={<PrivateRoute redirectTo="/login"><Quiz setPage={setPage}/></PrivateRoute>} />
        <Route path="/ranking" element={<PrivateRoute redirectTo="/login"><Rankings setPage={setPage} /></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute redirectTo="/login"><Quizes setPage={setPage} /></PrivateRoute>} />
        <Route path="*" element={<NoMatch setPage={setPage} />} />
      </Routes>    
     <Footer page={page} />
     <AnimatePresence>
     { isModalOutClientVisible ?
     <ModalOutClient setIsModalOutClientVisible={setIsModalOutClientVisible} />
     : null
     }
    </AnimatePresence>    
     
   </BrowserRouter>    
  )
}

export default App
