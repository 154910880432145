import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import App from './App'
import reducers from './reducers'
//import {IntlProvider} from 'react-intl';
import reportWebVitals from './reportWebVitals'
import './assets/scss/main.scss'

//import Spanish from './lang/es.json';
//import Portugues from './lang/pt.json';
import Wrapper from "./components/lang/Wrapper";

//const locale = navigator.language;
//let lang = locale==="pt"?Portugues:Spanish;
   


const container = document.getElementById('root')
const root = createRoot(container)
root.render( <React.StrictMode>
                <Provider store={createStore(reducers)}>
                    <Wrapper>
                        <App />
                    </Wrapper>
                </Provider>
            </React.StrictMode> )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
