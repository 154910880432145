import {React/*, useState, useEffect, useContext*/} from 'react'
import Casco from '../common/Casco'

const MyRanking = (props) => {

    const myTeam = () =>{
        if (props.active===2){
            if (props.data.idusuarioCreador===props.user.id.idusuario){
                return "my-team";
            }
        }
        return "not-my-team";
    }

    return (<div className={"my-ranking "+myTeam()}>
        <div className="position">
        <div className="order">
                <span>{parseInt(props.data.posicion)===0?"-":props.data.posicion}</span>
            </div>
            <div className="user-info">
                <div className="left">                                    
                    {parseInt(props.active)===2?<Casco id={props.data.idequipo} nick={props.data.nombre}></Casco>: 
                        null
                    }
                    { parseInt(props.active)!==2?
                        <Casco id={props.data.idusuario} nick={props.data.nickname}></Casco>:null                        
                    }
                </div>
                <div className="center">
                    <span className="nick">{ props.active===2?props.data.nombre:props.data.nickname}</span>
                    <div className="bottom">
                        {Array.isArray(props.data)?<>
                            <span className="hits">{props.data[0].totalAciertos} hits</span>
                            <span className="time">{props.data[0].totalTiempo}&apos;&apos;</span>                                                      
                        </>:<>
                            <span className="hits">{props.data.totalAciertos} hits</span>
                            <span className="time">{props.data.totalTiempo}&apos;&apos;</span>                                                      
                        </>}
                    </div>                        
                </div>
            </div>
        </div>
    </div>)
}
export default MyRanking