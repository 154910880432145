//import React from 'react'
import {FormattedMessage} from 'react-intl';
import logosantander from '../../assets/images/santander-logo.svg'
import twitter from '../../assets/images/twitter.svg'
import linkedin from '../../assets/images/linkedin.svg'
import facebook from '../../assets/images/facebook.svg'
import instagram from '../../assets/images/instagram.svg'
import youtube from '../../assets/images/youtube.svg'


const Footer = (/*props*/) => {
  //if (props.page==="login") return null;
  //return null;
  return <footer className="footer">
    <div className="container">
      <div className="flex">
      <img src={logosantander} className="logo" alt="Banco Santander" />
        <p>        
          <FormattedMessage
            id = "app.footer.text"
            defaultMessage=""
          />
        </p>
        <div className="rrss">
          <ul>
            <li><a href="#"><img src={twitter}></img></a></li>
            <li><a href="#"><img src={linkedin}></img></a></li>
            <li><a href="#"><img src={facebook}></img></a></li>
            <li><a href="#"><img src={instagram}></img></a></li>
            <li><a className="yt" href="#"><img src={youtube}></img></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
}

export default Footer