import React from 'react'

function assignBolitaStyle(bolitas,divBolitas,historico,numPreguntas){
	var tramoColor="tramo-rojo";

	const number=historico?.length;

	for(var i=0;i<numPreguntas;i++){
		//seleccionamos el color y tal de la bolita y el color del tramo
		if(i<historico?.length){
			if(historico[i]==='1'){
				bolitas[i]="bolita acierto";
			}else{
				bolitas[i]="bolita fallo";
			}
			tramoColor="tramo-blanco"

		}else if(i===number){
			bolitas[i]="bolita activa"
			tramoColor="tramo-rojo"
		}else{
			bolitas[i]="bolita"
			tramoColor="tramo-rojo"
		}
		//controlamos la curva del inicial y el final del tramo
		if(i==0){
			divBolitas[i]=<div key={"ball-"+i} className={"tramo-inicial " + tramoColor}><span className={bolitas[i]}></span></div>;
		}else if (i==numPreguntas-1){
			divBolitas[i]=<div key={"ball-"+i} className={"tramo-final " + tramoColor}><span className={bolitas[i]}></span></div>;
		}else{
			divBolitas[i]=<div key={"ball-"+i} className={"tramo " + tramoColor}><span className={bolitas[i]}></span></div>;
		}
	}
}
const ProgressBar = (props) => {
	//historico
	const bolitas=[];
	const divBolitas=[];
	
	assignBolitaStyle(bolitas,divBolitas,props.historico,props.numPreguntas);

     return <> 
				<div className="progressBar">
					{divBolitas.map(ball => (
						ball
					))}
				</div>	
			</>

}

export default ProgressBar
