import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import Spanish from '../../lang/es.json';
//import Portugues from '../../lang/pt.json';
import English from '../../lang/en.json';


export const Context = React.createContext();

//console.log("navigator.language", navigator.language);
const local = navigator.language;

let lang = (local.includes("en"))?English:Spanish;

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLanguage(e) {
        //console.log("selectLanguage", e);
        const newLocale = e;//.target.value;
        setLocale(newLocale);
        if (newLocale.toLowerCase() === 'es') {
            setMessages(Spanish);
        } 
        if (newLocale.toLowerCase() === 'en'){
            setMessages(English);
        }
    }

    return (
        <Context.Provider value = {{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={ local.includes("e")?"en":"es" }>
                {props.children}
            </IntlProvider>
        </Context.Provider>

    );
}


export default Wrapper;