import {React, useState, useEffect, useContext} from 'react'
import axios from 'axios'
import { useLocation, useNavigate/*, Link*/  } from 'react-router-dom'
import { /*FormattedMessage, */ useIntl } from 'react-intl';
import { connect } from 'react-redux'
import { setUser } from '../actions'
import {Context} from "./lang/Wrapper"
import Code from './Login/Code'
import ModalF1 from './common/ModalF1'

const Login = (props) => {
    const intl = useIntl();
    const context = useContext(Context);
    let navigate = useNavigate();    
    const [loading, setLoading] = useState(true);      
    var search = useLocation().search;        
    //const [userId, setUserId] = useState(null);
    //const [userEmail, setUserEmail] = useState(null);
    //const [invalidMail, setInvalidMail] = useState(null);
    const [showModal, setShowModal] = useState(false);
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
      });

    useEffect(() => {    
        props.setPage('login')
        
        const lang = new URLSearchParams(search).get('lang');
        var goToDashboard = true;
        if (lang =="en") {context.selectLanguage(lang);}
        
        //if token in localStorage try access APi with it to avoid re-login...
        if (localStorage.getItem("JWT") !== null){
            instance.get('/api/user')
            .then( (response) =>{
                props.setUser({"id": response.data.user});           
                if (goToDashboard) {
                    navigate("/quiz/1");
                }
            })
            .catch( (/*error*/) =>{                
                setLoading(false);                                      
            })        
        } else {
            setLoading(false);            
        }       
    },[]);   
    
  
    const setUser = (id) =>{
        props.setUser({"id":id});
    }
   
    const getClasses = () =>{       
        return "home login";
    }
   

    return <>
        {showModal?<ModalF1 text={ intl.formatMessage({ id:"app.code.resend"})} close={ ()=> setShowModal(false)} buttonText={ intl.formatMessage({ id: 'app.entrar' }) } />:null}
        <main className={getClasses()/*(userId === null && userEmail !== null)?"home login register ":"home login"*/}>
        <div className="intro">
            <div className="container" style={{ padding: "0" }}>
                <div className="content">                    
                    <div className="colRight">                       
                        { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:null }                                              
                        <Code                            
                            setUser={ (id) => setUser(id) }
                            showModal={ ()=> setShowModal(true)}
                        ></Code>                                                                 
                    </div>
                </div>
            </div>
        </div>
    </main>
    </>
}
const mapStateToprops = (state)=>{        
    return {...state};  
}
//export default Login
export default connect(mapStateToprops,{setUser:setUser})(Login)