import React from 'react'
//import Image from '../assets/images/image.png' //"https://via.placeholder.com/434x604"
import { Link } from 'react-router-dom'
import { /*FormattedMessage, */ useIntl } from 'react-intl';

const NoMatch = (props) => {
  const intl = useIntl();

  React.useEffect(() => {
    props.setPage('404')
  }, [])

  return <main className="quizes">    

    <div className="intro">
      <div className="container">
        <div className="content">
          {/*<div className="colLeft">
            <img src={Image} className="imgIntro" alt="" />
          </div>*/}

          <div className="colRight">
            <h3 className="title">{ intl.formatMessage({ id:"app.404.404"})}</h3>
            <p className="desc">{ intl.formatMessage({ id:"app.404.notFound"})}</p>
            <p className="desc"></p>
            <p className="desc"></p>        
            <br></br>
            <br></br>    
            <Link to="/" className="btn btnPrimary">{ intl.formatMessage({ id:"app.404.back"})}</Link>
          </div>
        </div>
      </div>
    </div>

  </main>
}

export default NoMatch