//import React from 'react'
import {React, useState/*, useEffect, useContext*/} from 'react'
//import PropTypes from "prop-types";
import {FormattedMessage } from 'react-intl';
import Api from '../Api/Api'
import { useNavigate  } from 'react-router-dom'



const Code = () => {
    let navigate = useNavigate();
    //const intl = useIntl();
    const [code, setCode] = useState(""); 
    const [error, setError] = useState(false); 

    /*React.useEffect(() => {               
    }, [])*/

    const handleSubmit = (event) => {        
        Api.post('/api/login', { "name": code })
        .then( (response) => { 
            console.log(response);                         
            localStorage.setItem("JWT", response.data.token);                
            navigate("/dashboard");                       
        })
        .catch(error=>{
            console.log(error.response.data);
            setError(true);            
        });
        event.preventDefault();
    }


return <>
    <h3 className="title">How shall we call you?</h3>
    <p>Choose a nickname that can help us identify you in our ranking. </p>    
    <form onSubmit={event => handleSubmit(event) }>        
        <div className="formGroup relative">                            
            <input 
                onChange={event => { setError(false); setCode(event.target.value)} } 
                className="formControl" 
                type="text"
                placeholder="Type here"
                maxLength={10}
                minLength={3}                 
            />
            <label>Nickname</label>
            {error?<label className="error">
                <FormattedMessage
                    id = "app.code.error"
                    defaultMessage="Invalid code"/>
                </label>:null}
        </div>
        
        <button className="btn btnPrimary"> Continue</button>
    </form>
    <br/>
   
   
    </>
}
export default Code