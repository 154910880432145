import React, { useState, useEffect, useContext } from 'react'
//import { Link } from 'react-router-dom'
import { useNavigate/*, useLocation*/ } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import {Context} from "../lang/Wrapper";
import axios from 'axios'
import Spain from '../../assets/images/flags/spain.svg'
import Trophy from '../../assets/images/trophy.svg'
import { /*FormattedMessage, */ useIntl } from 'react-intl';
//import {motion, AnimatePresence} from "framer-motion"

const Quizes = (props) => {
    const intl = useIntl();
    const context = useContext(Context);
    const navigate = useNavigate();
    const [gps, setGps] = useState(null);
    const [ranking, setRanking] = useState(null);

    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
      });

    useEffect(() => {        
        props.setPage('dashboard');        
        instance.get('/api/user').then( (response)=>{            
            props.setUser({"id": response.data.user});
            context.selectLanguage(response.data.user.idioma);
            //console.log("newUser:", response.data.user.newUser);
            if (response.data.user.newUser){
                if (!localStorage.getItem('INTRO')){
                    navigate("/intro");
                }
            }
        }).catch( (error)=>{
            console.log("Error:", error);
        })
        instance.get('/api/ranking').then( (response)=>{        
            setRanking(response.data.ranking);
        }).catch( (error)=>{
              console.log("Error:", error);
        })
        instance.get('/api/gps').then( (response)=>{           
            setGps(response.data.gps);
        }).catch( (error)=>{
              console.log("Error:", error);
        })       
    },[]);

    const getMonth = (date) => {
        var locale = props.user.id.idioma.toUpperCase()==="ES"?"es-ES":"en-EN";
        return date.toLocaleString(locale, { month: 'short' });
    }
    const getPosition = (number, isDate=false) =>{
        var locale = props.user.id.idioma.toUpperCase()==="ES"?"es-ES":"en-EN";
        if (locale === "en-EN"){
            if (number%10===1)return <sup>st</sup>;
            if (number%10===2)return <sup>nd</sup>;
            if (number%10===3)return <sup>rd</sup>;
            return <sup>th</sup>;
        }else{
            return isDate?"":"º";
        }
    }

    const getDate = (gp) =>{
        //return (16th-18th June) if same month or (30th June -2nd July) if diff month        
        var desde = new Date(gp.desde);
        var hasta = new Date(gp.hasta);
        console.log(desde,hasta);
        console.log(desde.getDate()+" "+desde.getMonth(), hasta.getDate()+" "+hasta.getMonth());
        if (desde.getMonth() === hasta.getMonth()){
            return <span>{desde.getDate()}{getPosition(desde.getDate(),true)}-{hasta.getDate()}{getPosition(hasta.getDate(),true)} {getMonth(desde)}</span>;
        } else {
            return <span>{desde.getDate()}{getPosition(desde.getDate(),true)} {getMonth(desde)}-{hasta.getDate()}{getPosition(hasta.getDate(),true)} {getMonth(hasta)}</span>;
        }        
    }

    const getDone = (gp) => {
        var result = false;
        if (ranking !== null){
            ranking.GP.forEach(element => {               
                if (element.id === gp.idgran_premio){                    
                    //console.log(element, gp, isNaN(element.current_user.totalAciertos));
                    if (!isNaN(element.current_user.totalAciertos)){
                        console.log("done", gp);
                        result = true;
                    }
                }
            });
        }
        return result;
    }

    const getGPPosition = (gp) => {
        var position="-";
        if (ranking!==null){
            ranking.GP.forEach(element => {
                //console.log(element, gp);
                if (element.id === gp.idgran_premio){
                    if (parseInt(element.current_user.totalAciertos)>0){
                        position = parseInt(element.current_user.posicion) + 1;
                    }                    
                }
            });
            return <span>{position}{getPosition(position)} { intl.formatMessage({ id:"app.dashboard.position"})}</span>;
        }
        return <span>- { intl.formatMessage({ id:"app.dashboard.position"})}</span>;
    }

    return <main className="quizes">

        {gps!==null&&ranking!=null?<>
        <h2>{ intl.formatMessage({ id:"app.dashboard.takepart"})}</h2>
        <p>{ intl.formatMessage({ id:"app.dashboard.select"})}</p>
        <div className="gps">
            {gps.map( gp =>{
                return (
                <div onClick={()=>{ navigate("/quiz/"+gp.idgran_premio) }} 
                className={ gp.active?"gp active":" gp " + gp.done?" done ":""+ gp.current?" current ":"" }
                key={"gp-"+ gp.idgran_premio  }>
                    {/*<span>{gp.idgran_premio}</span>*/}                    
                    {/*<img src={process.env.REACT_APP_URL + "images/flags/" + gp.icon} alt={gp.nombre}/>*/}
                    <div className="top">
                        <div className="left">
                            <img src={Spain} alt={gp.nombre}/>
                        </div>
                        <div className="right">
                            {gp.special?<img src={Trophy}/>:null}
                            {gp.current?<span>{ intl.formatMessage({ id:"app.dashboard.live"})}</span>:null}    
                        </div>
                    </div>
                    <h2>{gp.nombre.toUpperCase()}</h2>
                    {gp.active && !getDone(gp)?<span>{ intl.formatMessage({ id:"app.dashboard.available"})}</span>:null}
                    {getDone(gp)?<div>{getGPPosition(gp)}</div>:null}
                    {/*!gp.done?<div>{getDate(gp)}</div>:null*/}
                    {!gp.active?<div>{getDate(gp)}</div>:null}
                </div>)
            })
            }
        </div></>:null}
        
    </main>
}
//export default Quiz
const mapStateToprops = (state)=>{  
  return {...state};
}
export default connect(mapStateToprops,{setUser:setUser})(Quizes)