import { combineReducers } from 'redux'

const userReducer = (user = null, action) => {
    if (action.type==="USER"){
        return action.payload;
    }
    return user;
};

const pollReducer = (poll = null, action) => {
    if (action.type==="POLL"){
        return action.payload;
    }
    return poll;
};

export default combineReducers({
    user: userReducer,
    poll: pollReducer    
})