import React, { useState, useEffect} from 'react'
//import { useNavigate } from 'react-router-dom'
import ProgressBar from './ProgressBar'
import CircleTimer from './CircleTimer'
import axios from 'axios'
import Chrono from './Chrono'
import {useIntl} from 'react-intl'


const Question = (props) => {
	
    const [dQuestion,setDquestion] = useState(null);
	const [status, setStatus]=useState("desconocido");
	const [colorOpcion, setColorOpcion]=useState(["color-opcion","color-opcion","color-opcion"])
	const [statusComodin,setStatusComodin]=useState(false)
	const [opcionComodin,setOpcionComodin]=useState(-1)
	const [number, setNumber]=useState(1)
	const [statusChrono, setStatusChrono] = useState(0)
	
	const [boost, setBoost] = useState(false)
	const [pitstop, setPitStop] = useState(false)
	//const [timeChrono, setTimeChrono] = useState(0)
	
	const intl = useIntl()
	//let navigate = useNavigate();			
	
	const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,		
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

	const checkHistorico = (historico) => {
		let length=historico.length
	
		if(length!==0){
			if(historico[length-1] == 1 && historico[length-2]==1 && historico[length-3]==1 && !boost){
				//modal +3
				//setModalBoost(1)
				setBoost(true)

				
			}
			else if(historico[length-1] == 0 && historico[length-2]==0 && historico[length-3]==0 && !pitstop){
				//modal -3
				//setModalBoost(2)
				setPitStop(true)
				
			}
		}
	}

	function getQuestion() {	
		
		instance.get("api/quiz/"+props.number).then( (response)=>{
			console.log(response.data);	

			if(response.data.messages.success === "Quiz finished"){
				props.quizFinished(response.data)
			}else{
				setDquestion(response.data);
				setStatus(response.data.status);
				setStatusChrono(0)

				checkHistorico(response.data.data.historico)

				if(response.data.data.historico !== undefined){
					setNumber(response.data.data.historico.length+1)
				}
				//si ya ha usado comodin lo ponemos en gris
				if(response.data.data.comodines.quitar_una === 1){
					setStatusComodin(true)
				}
			}
		}).catch( (error)=>{
			console.log(error);
			if(error.status === 503){
				setStatus('timeout')
			}
			setStatus(error.response !== undefined ? error.response.status : "desconocido" );
		})
	}

	useEffect(()=>{ 
		//pedimos pregunta
		getQuestion()
	}, []);//[number])

	const colorRespuesta = (index,status) => {

		var color=colorOpcion
		if(status==="reset"){
			color[index]="color-opcion"
		}else{
			color[index]="color-opcion-"+status
		}
		setColorOpcion(color)
	}

	const getIndexRespuesta = (msg) => {
		var res=-1
		dQuestion.data.opciones.forEach((op,index) => {
			if(op==msg){
				res = index
			}
		})
		return res
	}

	function responder(index){
	
		//solo mandamos la respuesta al servidor si no pulsa el comodin
		if(!(statusComodin && index===opcionComodin) && !props.showModal && statusChrono!==2) {
			instance.post('api/quiz/answer/'+props.number, {
				codigo: dQuestion.data.codigo,
				respuesta: dQuestion.data.opciones[index],
				segundos: "20.4"
			})
			.then( (response) => {
				var index_correcta=-1
				//console.log(response.data)
				
				if(response.data.messages.success==="ok"){
					index_correcta=getIndexRespuesta(response.data.data.correcta)
					colorRespuesta(index_correcta,"correcta") 

					if(index!==index_correcta){
						colorRespuesta(index,"erronea")
					}
				}
				//setTimeChrono(response.data.tiempoTotal)
				setStatusChrono(2)
				//esperamos unos segundos y cambiamos de pregunta y volvemos al color original
				setTimeout( () => {
					if(statusComodin && opcionComodin!==-1){
						colorRespuesta(opcionComodin,"reset")
					}
					colorRespuesta(index,"reset")
					colorRespuesta(index_correcta,"reset")
					setStatusChrono(1)
					setOpcionComodin(-1)
					if(props.number!==props.numberQuestions){
						getQuestion();
					}
				},2000);
				
				setNumber(number+1)
				if(number>props.numPreguntas){
					setNumber(props.numPreguntas)
				}
			})
			.catch( (error) => {
				if(error.error==="Timeout"){
					if(statusComodin && opcionComodin!==-1){
						colorRespuesta(opcionComodin,"reset")
					}
					getQuestion();
					setNumber(number+1)
					if(number>props.numPreguntas){
						setNumber(props.numPreguntas)
					}
				}
				/*console.log(error.toJSON());*/
			
			});			
		}
	}
	const finishTimer = () => {
		if(number !== props.numPreguntas){
			if(statusComodin && opcionComodin!==-1){
				colorRespuesta(opcionComodin,"reset")
			}
			getQuestion()
			setNumber(Math.min(number+1, props.numberQuestions))
		}
	}	

	if(status!==200){
		//return <Modal title="Error al comunicarse con el servidor" message="Error {status}"/>
		return null
	}

	if(!dQuestion || dQuestion.data.opciones === undefined) return <p> No questions</p>;

	return  <> 				
				<div className="question">
				<ProgressBar historico={dQuestion.data.historico} numPreguntas={props.numberQuestions} /> 
					<div className="headerQuestion">
						<div className="numberContainer">
							<div className="circleContainer">
								<CircleTimer time={dQuestion.data.tiempo} duration="60" number={Math.min(number, props.numberQuestions)} onFinish={finishTimer}/>
							</div>
							<div className='txtOf'>{intl.formatMessage({ id: 'app.quiz.of' }) + " " + props.numberQuestions}</div>
						</div>
					
						<div className="chronoContainer">
							<Chrono time={dQuestion.data.tiempoTotal} status={statusChrono}/>
						</div>
						{/*<div className='pauseContainer' onClick={pauseQuestion}>
							<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="11.5" cy="11.5" r="10" stroke="#EC0000" strokeWidth="3"/>
								<line x1="8.48734" y1="6.17073" x2="8.48734" y2="16.8293" stroke="#EC0000" strokeWidth="3"/>
								<line x1="15.1835" y1="6.17073" x2="15.1835" y2="16.8293" stroke="#EC0000" strokeWidth="3"/>
							</svg>
						</div>*/}
					</div>
					<div className="containerQuestion">
						<div className="pregunta">{dQuestion.data.pregunta}</div>
						<ul>
							{dQuestion.data.opciones.map((op,key) =>
								dQuestion.data.opciones!=='' ?
									<li name={'opcion-'+key} className={'opcion ' + colorOpcion[key]} key={op+key} onClick={(e)=> {
										responder(key,e)
									}}>{op}</li>
								:''
							)}
						</ul>
						
					</div>
					
				</div>
            </>
}

export default Question