import React from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from "framer-motion"
import logof1 from '../../assets/images/f1-logo.svg'
import logosantander from '../../assets/images/santander-logo.svg'
//import left from '../../assets/images/chevron-left.svg'
//import burguer from '../../assets/images/burger.svg'
import play from '../../assets/images/play.svg'
import trophy from '../../assets/images/trophy.svg'
import awards from '../../assets/images/awards.svg'
import teams from '../../assets/images/teams.svg'
//import sustainability from '../../assets/images/sustainability.svg'
import profile from '../../assets/images/profile.svg'
import support from '../../assets/images/support.svg'
import logout from '../../assets/images/logout.svg'
import { useNavigate } from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import PropTypes from "prop-types";

const Header = (props) => { 
  let navigate = useNavigate();
  const [showMenu, setShowMenu] =React.useState(false);

  

   
  //if (props.page==="login" || props.page==="dashboard") return null;
  
  return <><header className="header">
      <div className="container">
        <div className="flex">
          <div className="left">
            
          </div>          
          <div className="right">
            { ['awards'].includes(props.page)?<>
              <div className="awards">
                <span className="awards-logo"></span>
                <h2><FormattedMessage id = "app.menu.awards" defaultMessage="Awards" /></h2>
              </div>
            </>: 
            <>
            <div>
              <img src={logof1} className="logo" alt="F1" />
            </div>
            <div >
              <img src={logosantander} className="logo" alt="Banco Santander" />
            </div></>}            
          </div>          
        </div>
      </div>
    </header>
    <AnimatePresence>
    {showMenu?
    <motion.div animate={{ opacity:1, transition: { duration: 0.2 }}} initial={{opacity:0}} exit={{opacity:0, transition: { duration: 0.2 } }} layout className="menu">
      <ul>
        <li className={props.page==="play"?"active":""}>
          <Link to="/dashboard"  onClick={()=>{ setShowMenu(false) }}>
            <img src={play} className="play" alt="play" /> 
            <span><FormattedMessage id = "app.menu.play" defaultMessage="Play" /></span>
          </Link>
        </li>
        <li className={props.page==="ranking"?"active":""}>
          <Link to="/ranking"  onClick={()=>{ setShowMenu(false) }}>
            <img src={trophy} className="trophy" alt="trophy" /> 
            <span><FormattedMessage id = "app.menu.ranking" defaultMessage="Ranking" /></span>
          </Link>
        </li>
        <li className={props.page==="awards"?"active":""}>
          <Link to="/awards"  onClick={()=>{ setShowMenu(false) }}>
            <img src={awards} className="awards" alt="awards" /> 
            <span><FormattedMessage id = "app.menu.awards" defaultMessage="Awards" /></span>
          </Link>
        </li>
        <li className={props.page==="teams"?"active":""}>
          <Link to="/teams" onClick={()=>{ setShowMenu(false) }}>
            <img src={teams} className="temas" alt="teams" /> 
            <span><FormattedMessage id = "app.menu.teams" defaultMessage="My teams" /></span>
          </Link>
        </li>
        {/*<li className={props.page==="learn"?"active":""}>
          <Link to="/learn"  onClick={()=>{ setShowMenu(false) }}>
            <img src={sustainability} className="sustainability" alt="sustainability" /> 
            <span><FormattedMessage id = "app.menu.sustainability" defaultMessage="Learn Sustainability F1" /></span>
          </Link>
        </li>*/}
        <li className={props.page==="profile"?"active":""}>
          <Link to="/profile"  onClick={()=>{ setShowMenu(false) }}>
            <img src={profile} className="profile" alt="profile" />
            <span><FormattedMessage id = "app.menu.profile" defaultMessage="My profile" /></span>
          </Link>
        </li> 
        <li className={props.page==="profile"?"active":""}>
          <Link to="/demo"  onClick={()=>{ setShowMenu(false) }}>
            <img src={profile} className="demo" alt="demo" />
            <span>Demo(test)</span>
          </Link>
        </li>        
      </ul>
      <ul className="menu-footer">
        <li className="">
          <Link to="/support"  onClick={()=>{ setShowMenu(false) }}>
            <span><FormattedMessage id = "app.menu.support" defaultMessage="Support" /></span>
            <img src={support} className="" alt="" />
          </Link>
        </li>
        <li>
          <button onClick={() => { localStorage.removeItem('JWT'); setShowMenu(false); navigate("/"); } }>            
            <span><FormattedMessage id = "app.menu.logout" defaultMessage="Logout" /></span>
            <img src={logout} className="" alt="" />
          </button>
        </li>
      </ul>
    </motion.div>:null}
    </AnimatePresence>
    </>
}
Header.defaultProps = {
  showNav: null,  
};

Header.propTypes = {
  showNav: PropTypes.bool,
  
};

export default Header