import React from 'react'
import { useNavigate } from 'react-router-dom'
import {motion} from "framer-motion"

const ModalOutClient = props => {
  let navigate = useNavigate();

  return <motion.div animate={{ opacity:1, transition: { duration: 0.2 }}} initial={{opacity:0}} exit={{opacity:0, transition: { duration: 0.2 } }} layout className="modal modalOutClient">
    <div className="modalWrapper">
      <button className="btnIcon btnClose" onClick={() => props.setIsModalOutClientVisible(false)}>
        <span className="icon-close"></span>
      </button>

      <div className="modalHeader">
        <p className="title">¿Quieres abandonar la<br /> encuesta de evaluación?</p>
      </div>

      <div className="modalContent">
        <p>Si decides salir, tus datos se guardarán para que puedas seguir con el proceso en otro momento. </p>
        <p>Solo tendrás que volver a acceder a través del link inicial que te enviamos.</p>
      </div>

      <div className="modalFooter">
        <button onClick={() => props.setIsModalOutClientVisible(false)} className="btn btnSecondary">Seguir proceso</button>
        <button onClick={ ()=>{ props.setIsModalOutClientVisible(false), navigate("/") } } className="btn btnPrimary">Abandonar encuesta</button>
      </div>
    </div>
  </motion.div>
}

export default ModalOutClient